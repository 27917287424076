import { deleteTemplatesApi, disable, fetchTemplates, update } from './templatesApi'
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject, setObject } from '../../Localstorage/localStorageService'
import { path } from 'ramda'
import {
  DELETE_TEMPLATE,
  DELETE_TEMPLATES,
  GET_TEMPLATES,
  TEMPLATES_DELETING,
  TEMPLATES_DELETING_SUCCESS,
  UPDATE_TEMPLATE,
} from './templatesActions'

export function receiveTemplates() {
  const user = getObject('user')
  return fetchTemplates(path(['authtoken'], user), path(['id'], user))
    .then((templates) => {
      setObject('templates', templates)
      return { response: templates }
    })
    .catch((err) => {
      setObject('templates', null)
      return err
    })
}

export function upateTemplateFromApi(data) {
  const user = getObject('user')
  return update(path(['authtoken'], user), path(['id'], user), data)
    .then((result) => {
      return { response: result }
    })
    .catch((err) => {
      return err
    })
}

export function deleteTemplateWithApi(data) {
  const user = getObject('user')
  return disable(path(['authtoken'], user), path(['id'], user), data)
    .then((templates) => {
      return { response: getObject('templates') }
    })
    .catch((err) => {
      return err
    })
}

export function deleteTemplatesWithApi(data) {
  const user = getObject('user')
  console.log()
  return deleteTemplatesApi(path(['authtoken'], user), path(['id'], user), data)
    .then((templates) => {
      return { response: getObject('templates') }
    })
    .catch((err) => {
      return err
    })
}

export function* getTemplatesFromApi() {
  const { response, error } = yield call(receiveTemplates)
  if (response) {
    yield put({ type: 'GET_TEMPLATES_SUCCESS', response })
  } else {
    yield put({ type: 'GET_TEMPLATES_FAILURE', error })
  }
}

export function* tryUpdateTemplate(data) {
  const { response, error } = yield call(upateTemplateFromApi, data)
  if (response) {
    yield put({ type: 'UPDATE_TEMPLATE_SUCCESS', response })
  } else {
    yield put({ type: 'UPDATE_TEMPLATE_FAILURE', error })
  }
}

export function* tryDeleteTemplate(data) {
  const { response, error } = yield call(deleteTemplateWithApi, data)
  if (response) {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!DELETE_TEMPLATE_SUCCESS', type: 'success' },
    })
    yield put({ type: 'DELETE_TEMPLATE_SUCCESS', response })
    yield call(getTemplatesFromApi)
  } else {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!DELETE_TEMPLATE_FAILURE', type: 'danger' },
    })
    yield put({ type: 'DELETE_TEMPLATE_FAILURE', error })
  }
}

export function* tryDeleteTemplates(data) {
  yield put({
    type: TEMPLATES_DELETING,
    response: { message: 'LOCALIZED!DELETE_TEMPLATE_SUCCESS', type: 'ongoing'  },
  })
  const { response, error } = yield call(deleteTemplatesWithApi, data)
  if (response) {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!DELETE_TEMPLATE_SUCCESS', type: 'success' },
    })
    yield put({ type: 'DELETE_TEMPLATE_SUCCESS', response })
    yield call(getTemplatesFromApi)
    yield put({
      type: TEMPLATES_DELETING_SUCCESS,
      response: { message: 'LOCALIZED!DELETE_TEMPLATE_SUCCESS', type: 'success' },
    })
  } else {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!DELETE_TEMPLATE_FAILURE', type: 'danger' },
    })
    yield put({ type: 'DELETE_TEMPLATE_FAILURE', error })
  }
}

export function* getTemplates() {
  yield takeEvery(GET_TEMPLATES, getTemplatesFromApi)
}

export function* updateTemplate() {
  yield takeEvery(UPDATE_TEMPLATE, tryUpdateTemplate)
}

export function* disableTemplate() {
  yield takeEvery(DELETE_TEMPLATE, tryDeleteTemplate)
}

export function* deleteTemplates() {
  yield takeEvery(DELETE_TEMPLATES, tryDeleteTemplates)
}
