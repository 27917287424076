import React, { Component, useState } from 'react'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import 'moment/locale/fi'
import ReactTooltip from 'react-tooltip'
import 'react-day-picker/lib/style.css'
import moment from 'moment'
import { push } from 'react-router-redux'
import { selectNewBill } from '../../components/api/Bill/billActions'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// Then import the virtualized Select HOC
import Select from 'react-select'
import { connect } from 'react-redux'
import BillRow from './BillRow'
import AddClientForm from '../../routes/Customers/components/AddClientForm'
import { deleteModal } from '../Modal/DeleteModal'
import { sendToBillingModal } from '../Modal/SendToBillingModal'
import { Modal } from 'react-bootstrap'
import './editor.scss'
import ContractorButton from '../BillEditor/contractor/ContractorButton'
import ExternalBill from '../BillEditor/external/ExternalBill'
import Checkbox from '@material-ui/core/Checkbox'
import SubcontractorBill from './subcontractor/SubcontractorBill'
import { equals, path, uniq } from 'ramda'
import { debounce } from 'debounce'
import { customDatePicker } from '../Utils/datePicker'
import AskAdvancePaymentForm from '../../routes/Customers/components/AskAdvancePaymentForm'
import { summary } from './Summary'
import { reactSelectStyles } from '../../styles/ReactSelectStyles'
import { DateTime } from 'luxon'
import CircleLoader from 'react-spinners/PulseLoader'

const override = {
  margin: '10px',
}
class BillEditor extends Component {
  state = {
    confirmModal: false,
    specificationDetails: '',
    notSearched: true,
    advancePaymentModalOpen: false,
  }

  componentDidMount() {
    this.setState({
      confirmModal: false,
      sendModal: false,
      specificationDetails: '',
    })
    this.getPersonAndbillCustomers = this.getPersonAndbillCustomers.bind(this)
    this.UNSAFE_componentWillReceiveProps(this.props)
    this.findCustomers = debounce(this.props.findCustomers.bind(this), 500)
  }

  openCustomerModal = (notSearched, state) => {
    const { sendNotifications } = this.props
    if (notSearched) {
      // sendNotifications('customerNotSearched', 'warning')
      this.setState({
        modalOpen: true,
      })
    } else if (state !== 'OPEN') {
      // sendNotifications
      sendNotifications('invoiceDisabled', 'warning')
    } else {
      this.setState({
        modalOpen: true,
      })
    }
  }

  openSendToAprove = () => {
    const { bill, validateBill } = this.props
    validateBill(bill.id)
    this.setState({
      sendModal: true,
    })
  }

  askForAdvancePayment = () => {
    this.setState({
      ...this.state,
      advancePaymentModalOpen: true,
    })
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    const { translate, user, allCustomers, selectedBill, selected_bill_validation } = nextprops
    const { searchString } = this.state
    const result = this.getPersonAndbillCustomers(nextprops, searchString)
    if (allCustomers) {
      result.push(...this.getFoundList(allCustomers, translate))
    }
    const combinedCustomers = this.getAllCustomers(user, allCustomers, selectedBill)
    const reasons = this.getFormattedReasons(translate, selectedBill.language)
    this.setState({
      ...nextprops.selectedBill,
      calculated: nextprops.calculated,
      lastSaved: nextprops.selectedBill.lastSaved,
      specificationDetails: nextprops.selectedBill.specificationDetails,
      ownCustomers: result,
      allCustomers: combinedCustomers,
      customers: result,
      reasons: reasons,
      vatZeroReasonCode: nextprops.selectedBill.vatZeroReasonCode,
      selected_bill_validation: selected_bill_validation,
      saveBillableItem: nextprops.saveBillableItem,
    })
  }

  getAllCustomers = (user, allCustomers, selectedBill) => {
    const customers = []

    if (allCustomers && Array.isArray(allCustomers)) {
      customers.push(...allCustomers)
    }
    if (selectedBill && selectedBill.sharedCustomer) {
      customers.push(selectedBill.sharedCustomer)
    }
    if (user && user.sharedCustomers) {
      customers.push(...user.sharedCustomers)
    }
    return customers
  }

  getFoundList = (allCustomers, translate) => {
    let result = []
    if (allCustomers.length > 0) {
      result.push({
        label: translate('invoice.allCustomers'),
        value: '___ALL___',
        disabled: true,
      })
      result.push(
        ...allCustomers.map((customer) => {
          let label = customer.name
          if (customer.organization) {
            label = `${label} - ${customer.organization}`
          }
          return {
            label: label,
            value: customer.id,
          }
        })
      )
    }
    return result
  }

  closeCustomerModal = (e) => {
    this.setState({
      modalOpen: false,
    })
  }
  closeAskAdvancePaymentModal = () => {
    this.props.clearAdvanceBillCalculation()
    this.setState({
      ...this.state,
      advancePaymentModalOpen: false,
    })
  }

  getPersonAndbillCustomers(props, searchString) {
    const { translate, user, selectedBill } = props
    const customerList = []
    customerList.push({
      label: translate('invoice.addedCustomers'),
      value: '___PROFILE___',
      disabled: true,
    })
    const personSharedCustomers = (user && user.sharedCustomers) || []
    personSharedCustomers.forEach((customer) => {
      let label = customer.name
      let hit = true
      if (searchString) {
        if (label.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) >= 0) {
        } else {
          hit = false
        }
      }
      if (customer.organization) {
        label = `${label} - ${customer.organization}`
      }
      if (hit) {
        customerList.push({
          label: label,
          value: customer.id,
        })
      }
    })
    if (selectedBill && selectedBill.sharedCustomer) {
      let label = selectedBill.sharedCustomer.name
      if (selectedBill.sharedCustomer.organization) {
        label = `${label} - ${selectedBill.sharedCustomer.organization}`
      }
      customerList.push({
        label: label,
        value: selectedBill.sharedCustomer.id,
      })
    }
    return uniq(customerList)
  }

  onSubmitCustomer = (values) => {
    values.termofpayment = +values.termofpayment
    const callback = (obj) => {
      if (obj.success) {
        this.doSave({
          ...this.props.selectedBill,
          sharedCustomer: obj.success.data,
        })
        this.closeCustomerModal(true)
      } else {
        this.props.sendErrorMessage(obj.error)
      }
    }
    this.props.createCustomer({ values, callback })
  }

  onSubmitAskEnnakko = (values) => {
    this.props.createEnnakko({
      bill: this.props.bill,
      advancePaymentAmount: {
        ids: values.ids,
        billId: values.billId,
      },
      callback: this.closeAskAdvancePaymentModal,
    })
  }

  onRowAdd = () => {
    const billableitems = this.props.selectedBill.billableitems || []
    this.setState({
      saveBillableItem: true,
    })
    let date = moment().format('DD.MM.YYYY')
    for (let item of this.props.selectedBill.billableitems) {
      date = DateTime.fromFormat(item.date, 'dd.MM.yyyy').plus({ days: 1 }).toFormat('dd.MM.yyyy')
    }
    const itemData = {
      hourlyprice: 0,
      kilometers: 0,
      alvPrice: 0,
      price: 0,
      alv: this.props.selectedBill.alv || 0,
      opened: 'closed',
      person: this.props.user,
      receipts: [],
      state: 'OPEN',
      destination: '',
      vehicle: 0,
      type: 'CONTRACT',
      date: date,
    }
    if (this.state.selectedTemplate) {
      itemData.template = this.state.selectedTemplate
    }
    const data = { ...this.props.bill, billableitems: [...billableitems, itemData] }
    const goToElement = (data) => {
      const lastItem = data.billableitems[data.billableitems.length - 1]
      const elementId = `billable-item-${lastItem.id}`
      const element = document.getElementById(elementId)
      // eslint-disable-next-line no-unused-expressions
      element?.scrollIntoView({
        behavior: 'smooth',
      })
    }
    this.props.createBillableItem(data, goToElement)
  }

  onRowUpdate = (data) => {
    this.props.updateBill({
      ...this.props.bill,
      billableitems: this.props.bill.billableitems.map((bi) => {
        if (bi.id === data.id) {
          return data
        } else {
          return bi
        }
      }),
    })
  }

  onRowDelete = (id) => {
    const newBillableItems = this.props.selectedBill.billableitems.filter((item) => item.id !== id)
    const bill = {
      ...this.props.selectedBill,
      billableitems: newBillableItems,
    }
    this.props.calculate(bill)
    this.props.deleteBillableItem({ id: id, bill: bill })
  }

  onCustomerChange = (e) => {
    if (!e || !e.value) {
      return
    }
    const customerData = (this.state.allCustomers || []).find((customer) => {
      if (customer && customer.id === e.value) {
        return customer
      }
      return null
    })

    this.doSave({
      ...this.props.selectedBill,
      sharedCustomer: customerData,
    })
  }

  onLanguageChange = (e) => {
    if (!e || !e.value) {
      return
    }
    this.doSave({
      ...this.props.selectedBill,
      language: e.value,
    })
  }

  selectCustomer = (value) => {
    this.doSave({
      ...this.props.selectedBill,
      sharedCustomer: value,
    })

    this.setState({
      ...this.state,
      modalOpen: false,
    })
  }

  onReasonChange = (e) => {
    const val = e ? e.target.value : null
    this.doSave({
      ...this.props.selectedBill,
      vatZeroReasonCode: val,
    })
  }

  onAlvChange = (e) => {
    const val = e ? e.target.value : null
    const newState = {
      ...this.props.selectedBill,
      alv: Number(val),
    }
    if (val) {
      newState.vatZeroReasonCode = null
    }
    this.doSave(newState)
  }

  onDateChange = (day) => {
    if (!day) {
      return
    }
    if (!day.isValid() || day.isBefore(moment().startOf('day'))) {
      day = moment().startOf('day')
      this.props.notify({
        message: 'LOCALIZED!WRONG_DATE',
        duration: 1500,
        type: 'error',
      })
    }
    this.doSave({
      ...this.props.selectedBill,
      billingdate: day.format('DD.MM.YYYY'),
    })
  }

  onDueDateChange = (day) => {
    if (!day) {
      return
    }
    if (!day.isValid() || day.isBefore(moment().startOf('day'))) {
      day = moment().startOf('day')
      this.props.notify({
        message: 'LOCALIZED!WRONG_DATE',
        duration: 1500,
        type: 'error',
      })
    }
    this.doSave({
      ...this.props.selectedBill,
      exactDueDate: day.format('DD.MM.YYYY'),
    })
  }

  onDueDateInput = (e) => {
    if (e.target.value && !moment(e.target.value, 'DD.MM.YYYY', true).isValid()) {
      this.props.notify({
        message: 'LOCALIZED!INVALID_DATE',
        duration: 1500,
        type: 'error',
      })
    }
  }

  onReverseChange = (e) => {
    const value = e.target.checked

    this.setState({
      inverseALV: e.target.checked,
    })
    const newValues = {
      ...this.props.selectedBill,
      inverseALV: value,
    }
    if (value) {
      newValues.alv = 0
      newValues.vatZeroReasonCode = null
    }
    this.doSave(newValues)
  }

  onTextChange = (e) => {
    this.setState({
      specificationDetails: e.target.value,
    })
  }

  onTextChangeSave = (e) => {
    this.doSave({
      ...this.props.selectedBill,
      specificationDetails: e.target.value,
    })
  }
  onYourcodeChange = (e) => {
    this.setState({
      yourcode: e.target.value,
    })
  }

  onYourcodeChangeSave = (e) => {
    this.doSave({
      ...this.props.selectedBill,
      yourcode: e.target.value,
    })
  }

  onBillCustomerChange = (e) => {
    const value = e.target.checked
    this.setState({
      billReimbursementsFromCustomer: value,
    })
    const newState = {
      ...this.props.selectedBill,
      billReimbursementsFromCustomer: value,
    }
    if (!value) {
      newState.separateReimbursements = false
    }
    // separateReimbursements
    this.doSave(newState)
  }

  onSeparateReimbursementsChange = (e) => {
    this.doSave({
      ...this.props.selectedBill,
      separateReimbursements: e.target.checked,
    })
  }

  constructionReimbursementsChange = (e) => {
    this.doSave({
      ...this.props.selectedBill,
      constructionReimbursements: e.target.checked,
    })
  }

  createNewBill = (bill) => {
    this.props.createBill(bill)
  }

  saveBill = (bill) => {
    this.doSave({
      bill,
    })
  }

  doSave = (bill) => {
    this.props.setBill(bill)
    if (!bill.sharedCustomer || !bill.billingdate) {
      return
    }
    if (!bill.id) {
      this.createNewBill(bill)
    } else {
      this.props.updateBill(bill)
    }
  }
  validate = (bill) => {
    const errors = []

    const day = moment(bill.billingdate, 'DD.MM.YYYY', true)
    if (!day.isValid() || day.isBefore(moment().startOf('day'))) {
      errors.push('INVALID_DATE')
    }

    bill.billableitems.forEach((billableItem) => {
      if (!billableItem.destination) {
        errors.push('error.billableItem.missing.destination')
      }
      if (!billableItem.date) {
        errors.push('error.billableItem.missing.date')
      }
      if (!billableItem.alvPrice) {
        if (isNaN(!billableItem.alvPrice)) {
          errors.push('error.billableItem.missing.alvPrice')
        }
      }
      if (billableItem.kilometers && !billableItem.vehicleRegisterNumber) {
        if (billableItem.vehicle === '0' || billableItem.vehicle === 0) {
          errors.push('error.billableItem.missing.vehicleRegisterNumber')
        }
      }
    })
    return errors
  }

  sendToAprove = () => {
    const errors = this.validate(this.props.selectedBill)
    if (errors.length) {
      errors.forEach((error) => {
        this.props.sendErrorMessage(error)
      })
    } else {
      const data = {
        ...this.props.selectedBill,
        state: 'PENDING',
        leftOutFromHome: this.state.approved_address_start || this.props.selectedBill.leftOutFromHome,
      }
      this.props.sendToAprove(data)
    }
    this.closeSendModal()
  }

  shouldComponentUpdate(nextprops, nextstate) {
    const should = equals(nextstate, this.state)
    return !should || this.props.locale !== nextprops.locale
  }

  onTemplateChange = (e) => {
    this.setState({
      selectedTemplate: parseInt(e.target.value),
    })
  }

  onSelectTemplateChange = (e) => {
    this.setState({
      selectedTemplate: e.value,
    })
  }

  openDeleteModal = () => {
    this.setState({
      confirmModal: true,
    })
  }

  onDeleteBill = () => {
    this.setState({
      confirmModal: false,
    })
    this.props.deleteBill(this.props.selectedBill)
  }

  closeModal = () => {
    this.setState({
      confirmModal: null,
    })
  }

  closeSendModal = () => {
    this.setState({
      sendModal: false,
    })
  }

  onInputKeyDown = (key) => {
    if (this.state.searchString !== key) {
      this.findCustomers(key)
      this.setState({
        ...this.state,
        searchString: key,
        notSearched: false,
      })
    }
  }

  collapseFn = (val) => {
    this.setState({
      ...this.state,
      collapseAll: val,
    })
  }

  approveAddressStart = (val) => {
    this.setState({
      ...this.state,
      approved_address_start: val,
    })
  }

  getFormattedReasons = (translate, billLanguage) => {
    let vals = []
    const lang = billLanguage === 'eng' ? 'en' : 'fi'
    vals.push({
      label: translate(`invoice.emptyVatZeroReason`, null, { defaultLanguage: lang }),
      value: '',
    })
    for (var i = 1; i < 11; i++) {
      vals.push({
        label: translate(`invoice.vatZeroReasons.${i}`, null, { defaultLanguage: lang }),
        value: i,
      })
    }
    return vals
  }

  getContent = (disabled) => {
    const { translate } = this.props
    if (disabled) {
      return translate('notification.invoiceDisabled')
    }
    return null
  }
  notReadyForAdvance = () => {
    const state = this.state?.state
    if (state === 'PAID') {
      this.props.sendErrorMessage('INVOICE_PAID')
    } else if (state === 'OPEN') {
      this.props.sendErrorMessage('INVOICE_NOT_SENT')
    } else {
      this.props.sendErrorMessage('ADVANCE_CANNOT_BE_ASKED')
    }
  }
  getAdvanceButton = (state) => {
    const { translate } = this.props
    const disabled = !(state === 'PENDING' || state === 'TAKEN' || state === 'CLOSED' || state === 'APPROVED')
    let onClick = disabled ? this.notReadyForAdvance : this.askForAdvancePayment
    const style = disabled ? 'disabled' : ''
    const str = `${style} btn btn-success`
    return (
      <div className="col-sm-offset-9 col-xs-offset-8 col-xs-4 col-sm-3" style={{ marginTop: '20px' }}>
        <button className={str} onClick={onClick}>
          {translate('invoice.askAdvancePayment')}
        </button>
      </div>
    )
  }

  render() {
    const { translate, templates, user, locale, selectedBill, calculated } = this.props
    const { saveBillableItem } = this.state
    const customers = this.state.customers
    const languages = [
      { value: 'eng', label: 'English' },
      { value: 'fin', label: 'Suomi' },
    ]
    const bill = selectedBill
    if (!user || !templates || !bill || !calculated) {
      return <div></div>
    }

    const billingDate = bill.billingdate ? bill.billingdate : moment().format('DD.MM.YYYY')
    const exactDueDate = moment(bill.exactDueDate, 'DD.MM.YYYY')
    const templatesList = ((templates && templates.list) || []).map((template, key) => {
      if (template.showInResult) {
        return (
          <option value={template.id} key={key}>
            {template.text}
          </option>
        )
      }
      return null
    })
    const rows = (bill.billableitems || []).map((row, index) => {
      return (
        <BillRow
          key={index}
          disabled={bill.state !== 'OPEN'}
          billableItem={row}
          bill={bill}
          translate={translate}
          locale={locale}
          onUpdate={this.onRowUpdate}
          collapseAll={this.state.collapseAll}
          collapseFn={this.collapseFn}
          onDelete={this.onRowDelete.bind(this, row.id)}
          billReimbursementsFromCustomer={bill.billReimbursementsFromCustomer}
        />
      )
    })

    const hasCustomer = bill.sharedCustomer ? '' : 'no-value'
    const { confirmModal, sendModal, selected_bill_validation } = this.state
    const message = translate('invoice.messageForDelete')
    const DeleteModal = deleteModal(confirmModal, message, translate, this.closeModal, this.onDeleteBill)

    const sendMessage = translate('invoice.messageForAproveRequest')
    // lets do it here
    const SendToBillingModal = sendToBillingModal(
      sendModal,
      sendMessage,
      translate,
      this.closeSendModal,
      this.sendToAprove,
      selected_bill_validation,
      this.approveAddressStart,
      this.state
    )

    let state = bill.state || ''
    if (bill.openRequest) {
      state = 'OPEN_REQUESTED'
    }
    const stateClassName = state.toLowerCase()
    const selectedValueId = path(['sharedCustomer', 'id'], selectedBill)
    const selectedCustomer = customers?.find((a) => a.value === selectedValueId)
    let dayPickerInput = customDatePicker(billingDate, translate('invoice.date'), this.onDateChange, locale, state !== 'OPEN', this.onDueDateInput, {
      display: 'block',
      width: '100%',
      minWidth: '90%',
      lineHeight: '50px',
    })
    let dueDatePicker = customDatePicker(
      exactDueDate.format('DD.MM.YYYY'),
      translate('invoice.date'),
      this.onDueDateChange,
      locale,
      state !== 'OPEN',
      this.onDueDateInput,
      {
        display: 'block',
        width: '100%',
        minWidth: '90%',
        lineHeight: '50px',
      }
    )

    const pdfUrl = `/api/bill/${bill.id}/pdf?authtoken=${user.authtoken}`
    const yourcode = this.state.yourcode ? this.state.yourcode : ''
    const disabled = (state !== 'OPEN' && !!bill.id) || !bill.id

    const notOpen = state !== 'OPEN'
    const allowIfNew = !!bill.id
    const reasonsToIterate = this.state.reasons || []

    const day = moment().startOf('day')
    const daysBetween = exactDueDate.diff(day, 'days') || ' '
    // disable fields
    const disableSeparateReimbursements = disabled || !this.state.billReimbursementsFromCustomer
    const disableAlv = disabled || !!this.state.inverseALV
    const disableVatZeroReason = disableAlv || this.state.alv > 0
    const advanceButton = this.getAdvanceButton(state)
    const vatZeroReasons = reasonsToIterate.map((reason) => {
      return (
        <option key={reason.value} value={reason.value}>
          {reason.label}
        </option>
      )
    })
    const alvCodes = [0, 10, 14, 24, 25.5].map((code) => {
      return (
        <option key={code} value={code}>
          {code} %
        </option>
      )
    })
    let notSearched = this.state.notSearched
    const showTooltip = !(disabled || notSearched)
    const isWorker = user.personSettings.workerType === 'WORKER'
    const selectedLanguage = languages?.find((lang) => lang.value === selectedBill.language)

    const openBillBtn =
      bill.state === 'PENDING' ? (
        <div className="col-xs-3 col-sm-3 col-rights">
          <button className="btn btn-accept" onClick={() => this.props.openBill(selectedBill)}>
            <span>{translate('invoice.open')}</span>
          </button>
        </div>
      ) : undefined
    const loading = true
    const color = '#ffffff'
    const list = templates && templates.list ? templates.list.map(t => ({ value: t.id, label: t.text })) : []
    const options = [
      { value: '', label: translate('misc.emptyRow') },
      ...list
    ];


    return (
      <div className="row">
        <div className="dashboard new-invoice">
          <div className="confines">
            <div className="col-lg-12">
              <div style={{ height: '52px' }} className="panel info-title row">
                <div>
                  <span className="pull-left clearfix">{!bill.id && translate('invoice.titleNew')}</span>
                </div>
                <div className="grow text-center">
                  <span className={'status ' + stateClassName}>{translate('invoice.state.' + state)}</span>
                </div>
                <div className="text-right">
                  <span className="invoice-id">
                    {translate('invoice.invoiceId')}: {(bill && bill.id) || ''}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 ">
                  <div className="form-group">
                    <label className="dkblue">{translate('invoice.customer')}</label>
                    <div className="row">
                      <div className="col-sm-8">
                        <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                          <Select
                            disabled={disabled && allowIfNew}
                            options={customers}
                            value={selectedCustomer || ''}
                            placeholder={translate('misc.select')}
                            className={hasCustomer}
                            inputProps={{ className: 'form-control22' }}
                            clearable={false}
                            autoload={false}
                            onInputChange={this.onInputKeyDown}
                            onBlurResetsInput={false}
                            onSelectResetsInput={false}
                            onCloseResetsInput={false}
                            filterOptions={(options, filter, currentValues) => {
                              // Do no filtering, just return all options
                              return options
                            }}
                            onChange={this.onCustomerChange}
                            styles={reactSelectStyles}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4" data-tip data-for="addCustomer" data-iscapture="true" data-tip-disable={showTooltip}>
                        <div>
                          <ReactTooltip id="addCustomer" getContent={() => this.getContent(notOpen)} effect="solid" delayHide={1000} />
                          <button
                            type="button"
                            disabled={notOpen}
                            onClick={() => this.openCustomerModal(notSearched, state)}
                            className="btn btn-success medium-margin"
                          >
                            <span>
                              <span className="glyphicon icon-plus-white"></span>
                              {translate('invoice.addCustomer')}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-xs-6 col-sm-3">
                      <label className="dkblue">{translate('invoice.date')}</label>
                      <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                        {/* päiväys */}
                        {dayPickerInput}
                      </div>
                    </div>
                    <div className="form-group col-xs-6 col-sm-3">
                      <label className="dkblue">{translate('invoice.dueDate')}</label>
                      {dueDatePicker}
                    </div>
                    <div className="form-group col-xs-6 col-sm-3">
                      <label className="dkblue">{translate('invoice.daysUntilDueDate')}</label>
                      <div className="form-control">{daysBetween}</div>
                    </div>
                    <div className="form-group col-xs-6 col-sm-3">
                      <label className="dkblue">{translate('invoice.language')}</label>
                      {
                        // Language
                      }
                      <Select
                        disabled={disabled}
                        options={languages}
                        value={selectedLanguage}
                        placeholder={translate('misc.select')}
                        inputProps={{ className: 'form-control22' }}
                        clearable={false}
                        onChange={this.onLanguageChange}
                        styles={reactSelectStyles}
                      />
                    </div>
                    {isWorker === false && (
                      <div className="form-group col-xs-12 col-sm-6">
                        <div className="empty-label form-control">
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="scaled"
                                id="reverse"
                                checked={!!this.state.billReimbursementsFromCustomer}
                                disabled={disabled}
                                onChange={this.onBillCustomerChange}
                                value={!!this.state.billReimbursementsFromCustomer}
                              />
                            }
                            label={translate('invoice.billReimbursementsFromCustomer')}
                          />
                        </div>
                      </div>
                    )}
                    {isWorker === false && (
                      <div className="form-group col-xs-12 col-sm-6">
                        <div className="empty-label form-control">
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="scaled"
                                id="separate-checkbox"
                                checked={!!this.state.separateReimbursements}
                                disabled={disableSeparateReimbursements}
                                onChange={this.onSeparateReimbursementsChange}
                                value={!!this.state.separateReimbursements}
                              />
                            }
                            label={translate('invoice.separateReimbursements')}
                          />
                        </div>
                      </div>
                    )}
                    {/*
                    <div className="form-group col-xs-12 col-sm-6">
                      <div className="empty-label form-control">
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="separate-checkbox"
                              checked={!!this.state.constructionReimbursements}
                              disabled={disabled}
                              onChange={this.constructionReimbursementsChange}
                              value={!!this.state.constructionReimbursements}
                            />
                          }
                          label={translate('invoice.constructionReimbursements')}
                        />
                      </div>
                    </div>
                    */}
                    <div className="form-group col-xs-12 col-sm-12">
                      <div className="empty-label form-control">
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{
                                transform: 'scale(1.2)',
                              }}
                              id="inverse-checkbox"
                              checked={!!this.state.inverseALV}
                              disabled={disabled}
                              onChange={this.onReverseChange}
                              value={!!this.state.inverseALV}
                            />
                          }
                          label={translate('invoice.reverseVAT')}
                        />
                      </div>
                    </div>
                    <div className="form-group col-xs-12">
                      <label className="dkblue">{translate('invoice.yourcode')}</label>
                      <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                        <input
                          type="text"
                          disabled={disabled}
                          className="form-control input-lg"
                          value={yourcode}
                          placeholder={translate('invoice.yourcode_placeholder')}
                          onBlur={this.onYourcodeChangeSave}
                          onChange={this.onYourcodeChange}
                        />
                      </div>
                    </div>
                    <div className="form-group col-xs-12">
                      <label className="dkblue">{translate('invoice.invoiceText')}</label>
                      <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                        <textarea
                          disabled={true}
                          style={{ minWidth: '100%', maxWidth: '100%' }}
                          className="form-control invoice-text"
                          value={this.state.specificationDetails}
                          onBlur={this.onTextChangeSave}
                          onChange={this.onTextChange}
                        />
                      </div>
                    </div>
                    <div className="form-group col-xs-6 col-sm-6">
                      <label className="dkblue">{translate('invoice.alv')}</label>
                      <div className="row">
                        <div className="col-sm-12">
                          <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                            <select
                              disabled={disableAlv}
                              className="form-control"
                              value={this.state.alv || ''}
                              name="alv"
                              onChange={this.onAlvChange}
                            >
                              {alvCodes}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-xs-6 col-sm-6">
                      <label className="dkblue">{translate('invoice.vatzero')}</label>
                      <div className="row">
                        <div className="col-sm-12">
                          <div data-tip data-iscapture="true" data-for="global-field-disabled" data-tip-disable={!disableVatZeroReason}>
                            <select
                              disabled={disableVatZeroReason}
                              className="form-control"
                              value={this.state.vatZeroReasonCode || ''}
                              name="vatZeroReasonCode"
                              onChange={this.onReasonChange}
                            >
                              {vatZeroReasons}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('invoice.titleRows')}
              </div>
              <div className="row">
                <div className="col-xs-12 ">
                  <div className="head-text dkblue">{translate('invoice.description')}</div>
                  {rows}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="dkblue">{translate('invoice.copy')}</label>
                        <div className="row">
                          <div className="col-sm-8">
                            <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                              <Select
                                isDisabled={disabled}
                                name="templates"
                                styles={reactSelectStyles}
                                placeholder={translate('misc.select')}
                                inputProps={{ className: 'form-control22' }}
                                value={this.state.template}
                                onChange={this.onSelectTemplateChange}
                                options={options}
                              />
                            </div>

                          </div>
                          <div className="col-sm-4 form-group">
                            <div
                              data-tip={bill.id ? translate('tooltip.invoiceDisabled') : translate('tooltip.invoiceMissingFields')}
                              data-tip-disable={!disabled}
                              data-iscapture="true"
                            >
                              <button
                                disabled={disabled || saveBillableItem}
                                type="button"
                                className="btn btn-success medium-margin"
                                onClick={this.onRowAdd}
                              >
                                <div>
                                  <span className="glyphicon icon-plus-white"></span>
                                  <span>{translate('invoice.addRow')}</span>
                                  <span>
                                    <CircleLoader loading={saveBillableItem} size={20} cssOverride={override} color="#FFFFFF" />
                                  </span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <ExternalBill translate={translate} locale={locale} />
                    <SubcontractorBill translate={translate} locale={locale} />
                    <ContractorButton translate={translate} locale={locale} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="summary row">
                <div className="col-xs-12">
                  <span className="title sum dkblue">{translate('invoice.summary')}</span>
                </div>
                <div className="col-xs-12">{summary(calculated, bill, user, translate)}</div>
                {advanceButton}
              </div>

              <div className="row bottom-buttons">
                {openBillBtn && openBillBtn}
                {!disabled && (
                  <div className="col-xs-3 col-sm-3 col-rights">
                    <button disabled={disabled} className="btn btn-accept" onClick={this.openSendToAprove}>
                      <span>{translate('invoice.accept')}</span>
                    </button>
                  </div>
                )}
                <div className="col-xs-3 col-sm-2  col-rights">
                  <a className="btn btn-info" disabled={!allowIfNew} href={pdfUrl}>
                    <div className="a-button">{translate('invoice.preview')}</div>
                  </a>
                </div>
                <div className="col-xs-3 col-sm-2  col-rights">
                  <button className="btn btn-cancel" onClick={this.props.onClose}>
                    <span>{translate('invoice.cancel')}</span>
                  </button>
                </div>

                {!disabled && (
                  <div className="col-xs-3 col-sm-2  col-rights">
                    <button className="btn btn-cancel" disabled={!bill.id || state !== 'OPEN'} onClick={this.openDeleteModal}>
                      <span>{translate('invoice.delete')}</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {DeleteModal}
        {SendToBillingModal}
        <Modal show={this.state.modalOpen} onHide={this.closeCustomerModal} dialogClassName="modal-90w" animation={false} backdrop={'static'}>
          <div className="valign-helper">
            <Modal.Header closeButton>
              <Modal.Title>{translate('customers.details.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.modalOpen && (
                <AddClientForm
                  notSearched={notSearched}
                  translate={translate}
                  selectCustomer={this.selectCustomer}
                  handleSubmit={this.onSubmitCustomer}
                />
              )}
            </Modal.Body>
          </div>
        </Modal>
        <Modal
          show={this.state.advancePaymentModalOpen}
          onHide={this.closeAskAdvancePaymentModal}
          dialogClassName="modal-80w"
          animation={false}
          backdrop={'static'}
        >
          <div className="valign-helper">
            <Modal.Header closeButton>
              <Modal.Title>{translate('invoice.askAdvancePayment')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.advancePaymentModalOpen && (
                <AskAdvancePaymentForm
                  translate={translate}
                  bill={bill}
                  handleSubmit={this.onSubmitAskEnnakko}
                  cancel={this.closeAskAdvancePaymentModal}
                />
              )}
            </Modal.Body>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const salary = (state.calculator.lastCalculated && state.calculator.lastCalculated.salary) || {}
  const calculated = {
    ...state.calculator.lastCalculated,
    salary: {
      ...salary,
    },
  }
  const billLang = path(['bill', 'selectedBill', 'language'], state) || 'fin'
  const useLang = billLang === 'fin' ? 'fi' : 'en'
  const billLanguages = state.locale.languages.map((lang) => {
    const isActive = lang.code === useLang
    return {
      ...lang,
      active: isActive,
    }
  })
  const billLocale = {
    ...state.locale,
    languages: billLanguages,
  }
  return {
    user: state.user.user,
    allCustomers: state.customers.result,
    lastCreatedCustomer: state.user.lastCreatedCustomer,
    bills: state.bills.list,
    lastUpdatedBill: state.bills.lastUpdatedBill,
    templates: state.templates,
    locale: getActiveLanguage(billLocale).code,
    selectedBill: state.bill.selectedBill,
    calculated: calculated,
    translate: getTranslate(state.locale),
    customers: state.customers.customers,
    selected_bill_validation: state.bill.selected_bill_validation,
    saveBillableItem: state.bill.saveBillableItem || false,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createCustomer: (data) => dispatch({ type: 'CREATE_CUSTOMER', data }),
    setBill: (data) => dispatch(selectNewBill(data)),
    createBill: (data) => dispatch({ type: 'CREATE_BILL', data }),
    updateBill: (data) => dispatch({ type: 'UPDATE_BILL', data }),
    sendToAprove: (data) => dispatch({ type: 'SEND_TO_APPROVE', data }),
    validateBill: (id) => dispatch({ type: 'VALIDATE_BILL', id }),
    updateDueDate: (data) => dispatch({ type: 'UPDATE_DUE_DATE', data }),
    createBillableItem: (data, callback) => dispatch({ type: 'CREATE_BILLABLE_ITEM', data, callback }),
    deleteBillableItem: (data) => dispatch({ type: 'DELETE_BILLABLE_ITEM', data }),
    calculate: (data) => dispatch({ type: 'CALCULATE_FROM_BILL', data }),
    notify: (data) => dispatch({ type: 'SEND_NOTIFICATION', response: data }),
    deleteBill: (data) => dispatch({ type: 'DELETE_BILL', data }),
    openBill: (data) => dispatch({ type: 'OPEN_BILL', data }),
    sendErrorMessage: (message) =>
      dispatch({
        type: 'SEND_NOTIFICATION',
        response: { message: `LOCALIZED!${message}`, type: 'error' },
      }),
    findCustomers: (data) => dispatch({ type: 'FIND_CUSTOMERS', data }),
    redirect: (url) => dispatch(push(url)),
    sendNotifications: (message, type) =>
      dispatch({
        type: 'SEND_NOTIFICATION',
        response: { message: `LOCALIZED!${message}`, type: type },
      }),
    createEnnakko: (data) => dispatch({ type: 'CREATE_ADVANCE_PAYMENT', data }),
    clearAdvanceBillCalculation: () => dispatch({ type: 'CLEAR_CALCULATE_FROM_PARTIAL_BILL' }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillEditor)
