import React from 'react'
import {getTranslate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field, Formik} from 'formik'

/* eslint no-useless-escape: 0 */
const validate = values => {
  const errors = {}
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const requiredFields = [
    'email',
    'phone',
    'address',
    'postalcode',
    'password',
  ]
  if (values.email) {
    if (emailRegex.exec(values.email) === null) {
      errors['email'] = 'validation.not_an_email'
    }
  }
  if (values.password && values.password.length < 6) {
    errors['password'] = 'validation.passwordMustBe6Characters'
  }
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'validation.required'
    }
  })
  return errors
}

const renderField = ({
                       field, // { name, value, onChange, onBlur }
                       translate,
                       label,
                       handleChange,
                       form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                       ...props
                     }) => (
  <div className="form-group">
    <label>{label}</label>
    <div>
      <input
        type="text"
        className="form-control input-lg"
        value={field.value}
        {...field}
        {...props}
      />
      {errors[field.name] && touched[field.name] && (
        <span className="field-error">{translate(errors[field.name])}</span>
      )}
    </div>
  </div>
)
const RegisterForm = props => {
  const { initialValues, handleSubmit, translate } = props
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values)
      }}
    >
      {({
          handleSubmit,
          /* and other goodies */
        }) => (
        <form onSubmit={handleSubmit}>
          <div className="col-xs-10 col-xs-offset-1">
            <div className="col-xs-12">
              <Field
                name="email"
                type="email"
                label={translate('register.email')}
                placeholder={translate('register.emailPH')}
                translate={translate}
                component={renderField}
              />
            </div>
            <div className="col-xs-12">
              <Field
                name="phone"
                type="tel"
                label={translate('register.phoneNumber')}
                placeholder={translate('register.phoneNumberPH')}
                translate={translate}
                component={renderField}
              />
            </div>
            <div className="col-xs-12">
              <Field
                name="address"
                label={translate('register.address')}
                placeholder={translate('register.addressPH')}
                translate={translate}
                component={renderField}
              />
            </div>
            <div className="col-xs-12">
              <Field
                name="postalcode"
                label={translate('register.zipCode')}
                placeholder={translate('register.zipCodePH')}
                translate={translate}
                component={renderField}
              />
            </div>
            <div className="col-xs-12">
              <Field
                name="password"
                type="password"
                label={translate('register.password')}
                placeholder={translate('register.passwordPH')}
                translate={translate}
                component={renderField}
              />
            </div>
            <div className="col-xs-12">
              <Field
                name="promoCode"
                label={translate('register.promoCode')}
                placeholder={translate('register.promoCodePH')}
                translate={translate}
                component={renderField}
              />
            </div>
            <div className="col-xs-12 col-md-12">
              <div className="medium-margin">
                <button className="btn btn-success btn-block btn-lg" type="submit">
                  {translate('register.submit')}
                </button>
              </div>
            </div>
            <div className="row"></div>
          </div>
        </form>
      )}
    </Formik>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
