import {
  GET_TEMPLATES,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
  TEMPLATES_DELETING,
  TEMPLATES_DELETING_SUCCESS,
} from './templatesActions'

const templatesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TEMPLATES + '_SUCCESS':
      return {
        ...state,
        list: action.response,
      }
    case GET_TEMPLATES + '_FAILURE':
      return {
        ...state,
        list: [],
      }
    case UPDATE_TEMPLATE + '_SUCCESS':
      return {
        ...state,
      }
    case UPDATE_TEMPLATE + '_FAILURE':
      return {
        ...state,
      }
    case DELETE_TEMPLATE + '_SUCCESS':
      return {
        ...state,
      }
    case DELETE_TEMPLATE + '_FAILURE':
      return {
        ...state,
      }
    case TEMPLATES_DELETING:
      return {
        ...state,
        deleting: true,
      }
    case TEMPLATES_DELETING_SUCCESS:
      return {
        ...state,
        deleting: false,
      }
    default:
      return state
  }
}

export default templatesReducer
