import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import './templates.scss'
import Checkbox from '@material-ui/core/Checkbox'
import { DELETE_TEMPLATE, DELETE_TEMPLATES } from '../../components/api/Templates/templatesActions'
import PuffLoader from 'react-spinners/PuffLoader'

class Templates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      toggleDelete: 'none', // possible values: 'all', 'notShown', 'none'
      localTemplates: [], // local copy to track deletion checkbox states
      isDeleting: false, // local flag to show spinner
    }
  }

  componentDidMount() {
    this.props.getTemplates()
  }

  componentDidUpdate(prevProps) {
    // When the templates from props change, update our local copy.
    if (prevProps.templates.list !== this.props.templates.list) {
      this.setState({ localTemplates: this.props.templates.list })
    }
    // When deletion flag changes from true to false, hide the spinner.
    if (prevProps.deleting && !this.props.deleting) {
      this.setState({ isDeleting: false })
    }
  }

  onDelete = () => {
    // Called from the modal confirmation
    this.props.deleteTemplate(this.state.template)
    this.closeModal(true)
  }

  onChange = (template, e) => {
    // This function still updates the showInResult property immediately.
    ReactTooltip.rebuild()
    template.showInResult = !!e.target.checked
    this.props.updateTemplate(template)
  }

  // Update the deletion checkbox for a single template in the local state
  setCheckedForDeletion = (template, e) => {
    const checked = !!e.target.checked
    const updatedTemplates = this.state.localTemplates.map((t) => {
      if (t.id === template.id) {
        // assuming each template has a unique id
        return { ...t, delete: checked }
      }
      return t
    })
    this.setState({ localTemplates: updatedTemplates })
  }

  // Toggle the header checkbox across three states:
  // 'all' sets all delete checkboxes to true;
  // 'notShown' sets delete to true only when template.showInResult is false;
  // 'none' sets them all to false.
  // This only updates the localTemplates (i.e. the UI), not the store.
  toggleDelete = () => {
    let newToggleState
    if (this.state.toggleDelete === 'all') {
      newToggleState = 'notShown'
    } else if (this.state.toggleDelete === 'notShown') {
      newToggleState = 'none'
    } else {
      newToggleState = 'all'
    }

    const updatedTemplates = (this.state.localTemplates || []).map((template) => {
      let newDelete
      if (newToggleState === 'all') {
        newDelete = true
      } else if (newToggleState === 'notShown') {
        newDelete = !template.showInResult
      } else {
        newDelete = false
      }
      return { ...template, delete: newDelete }
    })

    this.setState({
      toggleDelete: newToggleState,
      localTemplates: updatedTemplates,
    })
  }

  // When the delete link is clicked, filter the selected templates and dispatch the delete action.
  // We set isDeleting to true here and then hide the spinner when the Redux deleting flag changes.
  deleteToggledModal = () => {
    this.setState({ deleteSelectedModal: true })
  }

  deleteToggled = () => {
    const selectedTemplates = (this.state.localTemplates || []).filter((template) => template.delete).map((template) => template.id)

    if (selectedTemplates.length === 0) return

    // Set local spinner flag before dispatching
    this.setState({ isDeleting: true })

    // Dispatch the deletion action; saga will handle the async deletion.
    this.props.deleteTemplates(selectedTemplates)
    this.setState({ deleteSelectedModal: false })
  }

  renderTemplates() {
    const templatesList = this.state.localTemplates || (this.props.templates && this.props.templates.list) || []
    return templatesList.map((template, key) => {
      return this.renderTemplate(template, key)
    })
  }

  openModal = (template) => {
    this.setState({ modalOpen: true, template })
  }

  closeModal = (e) => {
    this.setState({ modalOpen: false, template: null })
  }

  closeDeleteToggledModal = (e) => {
    this.setState({ deleteSelectedModal: false })
  }

  renderTemplate(template, key) {
    const checkbox = 'checkbox_' + key
    return (
      <div className="table-row" key={key}>
        <div className="field middle">
          <Checkbox
            className="scaled"
            onChange={this.setCheckedForDeletion.bind(this, template)}
            checked={template.delete || false}
            id={checkbox}
            value={template.delete || false}
          />
        </div>
        <div className="field middle">
          <Checkbox
            className="scaled"
            onChange={this.onChange.bind(this, template)}
            checked={template.showInResult || false}
            id={checkbox}
            value={template.showInResult || false}
          />
        </div>
        <div className="field">
          <span>{template.text}</span>
        </div>
        <div className="field middle">
          <button
            data-for="global-delete"
            data-tip
            data-iscapture="true"
            className="pull-right btn btn-square"
            onClick={this.openModal.bind(this, template)}
          >
            <i className="glyphicon icon-trash" />
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { translate } = this.props
    const templates = this.renderTemplates()
    const selectedRowCount = this.state.localTemplates.filter((t) => t.delete).length
    const selected = selectedRowCount > 0
    return (
      <div className="row">
        <div className="dashboard templates">
          <div className="col-lg-12">
            <div style={{ marginTop: '30px' }} className="panel info-title">
              {translate('templates.title')}
            </div>

            <div className="description">{translate('templates.description')}</div>
            <div className="row">
              <div className="table">
                <div className="table-header">
                  <div className="field">
                    <Checkbox
                      className="scaled"
                      onChange={this.toggleDelete}
                      checked={this.state.toggleDelete === 'all'}
                      indeterminate={this.state.toggleDelete === 'notShown'}
                      value={this.state.toggleDelete}
                    />
                    <button
                      type="button"
                      onClick={!this.props.templates?.deleting ? this.deleteToggledModal : undefined}
                      style={{
                        cursor: this.props.templates?.deleting ? 'not-allowed' : 'pointer',
                        pointerEvents: this.props.templates?.deleting ? 'none' : 'auto',
                        opacity: this.props.templates?.deleting ? 0.5 : 1,
                        background: 'none',
                        border: 'none',
                        padding: '0.5em 1em',
                        marginLeft: '8px',
                        font: 'inherit',
                        color: selected ? '#007bff' : '',
                        transition: ' color 0.3s, text-decoration 0.3s',
                      }}
                      onMouseOver={(e) => {
                        if (!this.props.templates?.deleting) {
                          e.target.style.color = '#3399ff' // lighter blue on hover
                          e.target.style.textDecoration = 'underline'
                        }
                      }}
                      onMouseOut={(e) => {
                        if (!this.props.templates?.deleting) {
                          e.target.style.backgroundColor = 'transparent'
                          e.target.style.textDecoration = 'none'
                          e.target.style.color = selected ? '#007bff' : ''
                        }
                      }}
                    >
                      {translate('templates.delete')}
                      {this.props.templates?.deleting && <PuffLoader size={25} color="#007bff" />}
                    </button>
                  </div>

                  <div className="field">{translate('templates.active')}</div>
                  <div className="field">{translate('templates.name')}</div>
                  <div className="field"></div>
                </div>
                {templates}
              </div>
            </div>
          </div>
        </div>
        <Modal show={!!this.state.modalOpen} onHide={this.closeModal} backdrop={'static'} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>{translate('templates.confirmDeleteTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="message">{translate('templates.confirmDeleteMessage')}</div>
          </Modal.Body>

          <Modal.Footer>
            <div className="row">
              <div className="col-xs-6">
                <Button variant="cancel" onClick={this.closeModal}>
                  {translate('misc.cancel')}
                </Button>
              </div>
              <div className="col-xs-6">
                <Button variant="danger" onClick={this.onDelete}>
                  {translate('misc.confirm')}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={!!this.state.deleteSelectedModal} onHide={this.closeDeleteToggledModal} backdrop={'static'} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>{translate('templates.confirmDeleteTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedRowCount > 1 && <div className="message">{translate('templates.confirmDeleteMultipleMessage', { n: selectedRowCount })}</div>}
            {selectedRowCount <= 1 && <div className="message">{translate('templates.confirmDeleteMessage')}</div>}
          </Modal.Body>

          <Modal.Footer>
            <div className="row">
              <div className="col-xs-6">
                <Button variant="cancel" onClick={this.closeDeleteToggledModal}>
                  {translate('misc.cancel')}
                </Button>
              </div>
              <div className="col-xs-6">
                <Button variant="danger" onClick={this.deleteToggled}>
                  {translate('misc.confirm')}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    templates: state.templates || {},
    // assuming your saga updates a deleting flag in the templates state:
    deleting: state.templates.deleting,
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTemplates: () => dispatch({ type: 'GET_TEMPLATES' }),
    updateTemplate: (data) => dispatch({ type: 'UPDATE_TEMPLATE', data }),
    deleteTemplate: (data) => dispatch({ type: DELETE_TEMPLATE, data }),
    deleteTemplates: (data) => dispatch({ type: DELETE_TEMPLATES, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates)
